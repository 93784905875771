<script setup>
/* eslint-disable */
import {ref} from 'vue'

const minConf = {
  0: 4,
  1: 3,
  2: 1,
  3: 0,
  4: 0,
}

const configs = {
  "x250": {
    0: 10,
    1: 8,
    2: 2,
    3: 0,
    4: 0,
  },
  "x550": {
    0: 10,
    1: 8,
    2: 4,
    3: 0,
    4: 0,
  },
  "x1k": {
    0: 10,
    1: 8,
    2: 6,
    3: 0,
    4: 0,
  },
  "x1.5k": {
    0: 10,
    1: 8,
    2: 5,
    3: 1,
    4: 0,
  },
  "x3k": {
    0: 10,
    1: 8,
    2: 5,
    3: 2,
    4: 0,
  },
  "x6k": {
    0: 10,
    1: 8,
    2: 5,
    3: 3,
    4: 0,
  },
  "x9k": {
    0: 10,
    1: 8,
    2: 5,
    3: 4,
    4: 0,
  },
  "x13k": {
    0: 10,
    1: 8,
    2: 5,
    3: 5,
    4: 0,
  },
  "x25k": {
    0: 10,
    1: 8,
    2: 5,
    3: 4,
    4: 1,
  },
  "x50k": {
    0: 10,
    1: 8,
    2: 5,
    3: 3,
    4: 2,
  },
}

const muls = {
  0: 1.2,
  1: 1.5,
  2: 2,
  3: 3,
  4: 6,
}

const chances = {
  0: 0.8333333333,
  1: 0.6666666667,
  2: 0.5,
  3: 0.3333333333,
  4: 0.1666666667,
}

const distribution = [
  {ind: 0, weight: 10},
  {ind: 1, weight: 8},
  {ind: 2, weight: 3},
  {ind: 3, weight: 3},
  {ind: 4, weight: 3},
]

let curStats = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
}

let roundsPlayed = 0

let selectedConfig = ref("x3k")

let balance = ref(10_000.00)
let currentWin = ref(0)
let currentMul = ref(0)

let inProgress = ref(false)
let showRes = ref(false)
let isFailed = ref(null)

const rtp = 0.9
const betSize = 1.0

let linesList = ref([])

function getDistribution() {
  let res = []
  let maxWeight = 0
  let maxInd = 0
  let mi = 4
  let conf = configs[selectedConfig.value]

  if (roundsPlayed < 8) {
    mi = 2
    conf = minConf
  }

  for (let i in distribution) {
    const ind = distribution[i]

    if (curStats[ind.ind] >= conf[ind.ind] || conf[ind.ind] === 0 || ind.ind > mi) {
      continue
    }

    maxWeight += ind.weight
    maxInd = ind.ind
    res.push(ind)
  }

  return [res, maxWeight, maxInd]
}


function getIndex() {
  const [distr, maxWeight, maxInd] = getDistribution()

  const weight = Math.random() * maxWeight
  let used = 0

  for (let i in distr) {
    const ind = distr[i]
    used += ind.weight

    if (weight <= used) {
      return ind.ind
    }
  }

  return maxInd
}

function pickLine() {
  showRes.value = false
  const ind = getIndex()
  currentMul.value = muls[ind]

  curStats[ind]++

  let line = []

  let bombsPicked = 0
  for (let i = 0; i < 6; i++) {
    if (bombsPicked < ind + 1 && Math.random() > chances[ind] || 5 - i <= ind - bombsPicked) {
      bombsPicked++
      line.push(false)
    } else {
      line.push(true)
    }
  }

  console.log("Line picked", ind, "mul", currentMul.value)
  console.log(line)

  return line
}

function startRound() {
  inProgress.value = true
  balance.value -= betSize
  balance.value = Math.round(balance.value * 100) / 100
  currentWin.value = betSize * rtp

  linesList.value.push(pickLine())
}

function pickUp() {
  isFailed.value = false
  showRes.value = true
  balance.value += currentWin.value
  balance.value = Math.round(balance.value * 100) / 100

  setTimeout(cleanup, 1700)
}

function cleanup() {
  isFailed.value = null
  showRes.value = false
  inProgress.value = false
  currentWin.value = 0
  currentMul.value = 0
  linesList.value = []
  curStats = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  }
  roundsPlayed = 0
}

function choose(symb) {
  showRes.value = true
  if (!symb) {
    isFailed.value = true
    setTimeout(cleanup, 1700)
    return
  }

  roundsPlayed++
  currentWin.value *= currentMul.value
  currentWin.value = Math.round(currentWin.value * 100) / 100

  if (roundsPlayed >= 15) {
    pickUp()
    return
  }
  setTimeout(() => {
    linesList.value.push(pickLine())
  }, 500)

}

</script>

<template>
  <div style="padding: 15px">
    <div class="h4">Select multiplier:</div>
    <button v-for="item in Object.keys(configs).reverse()" @click="selectedConfig=item" :key="item" class="btn"
            style="margin-right: 5px; margin-bottom: 5px;" :disabled="inProgress" :class="{ 'btn-primary': selectedConfig===item, 'btn-secondary': selectedConfig!==item }">{{ item }}
    </button>
    <br>
    <br>
    <span class="h4">Current balance: {{ balance }}&emsp;&emsp;Current win: {{ currentWin }}</span>
    <br>
    <span v-if="isFailed === null" class="h4">Rounds played: {{ roundsPlayed }}&emsp;&emsp;Current multiplier: {{ currentMul }}</span>
    <span v-if="isFailed === false" class="h2">Total win: {{ currentWin }}</span>
    <span v-if="isFailed === true" class="h2">Fail!</span>
    <br>
    <button v-if="!inProgress" @click="startRound" class="btn btn-success" style="margin-right: 5px;">Start round
    </button>
    <button v-if="inProgress" @click="pickUp" class="btn btn-danger" style="margin-right: 5px;" :disabled="isFailed !== null">Pick up</button>
    <br><br>

    <div id="app">
      <div class="chessboard">
        <div v-for="(line, rindex) in [].concat(linesList).reverse()" :key="rindex">
          <div v-if="rindex === 0 && !showRes" v-for="(isSafe, vindex) in line" :key="vindex" class="btn btn-primary"
               style="margin-right: 5px; margin-bottom: 5px; height: 30px; width: 30px"
               @click="choose(isSafe)"
          ></div>
          <div v-if="rindex !== 0 || showRes" v-for="(isSafe, vindex) in line" :key="vindex" class="btn"
               style="margin-right: 5px; margin-bottom: 5px; height: 30px; width: 30px"
               :class="{ 'btn-success': isSafe, 'btn-danger': !isSafe }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  font-weight: bold;
}

</style>
